'use client'

import { Database } from '@jobmojito/config/types/supabase'
import getClientSupabase from '@jobmojito/app/utils/getClientSupabase'

export default function logoutUser() {
  const supabase = getClientSupabase()
  supabase.auth.signOut().then(() => {
    window.location.href = '/login'
    window.location.reload()
  })
}
